@import "../utilities/mixins";

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  min-height: var(--body-min-height);
  margin: 0;
  @include font;
}

main {
  min-height: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include resetMargins;
}

p {
  @include resetMargins;
}

.btn-reset {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  -moz-outline: none;
}

.svg-logo {
  @include calculateItemSizeVH(186, 64);
}

.page {
  position: relative;
  display: none;
  min-height: inherit;
}

.page.active {
  display: flex;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;

  img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: cover;
    object-position: bottom right;
  }
}

@media (orientation: portrait) {
  .bg {
    img {
      object-position: top right;
    }
  }
}

@media (min-aspect-ratio: 2/1) {
  .bg {
    img {
      object-fit: contain;
    }
  }
}